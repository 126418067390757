<template>
  <div class="DefaultDialog-imageWrapper">
    <img
      class="DefaultDialog-image"
      :src="image"
      loading="lazy"
      aria-hidden="true"
    />
  </div>
  <button
    class="LoginDialog-close button--close"
    title="Dialog schließen"
    @click="close()"
  >
    <i class="las la-times"></i>
  </button>
  <div class="info-text">
    <div v-if="error" class="Message Message--error">{{ error }}</div>

    <div v-if="success" class="Message Message--success">{{ success }}</div>

    <form
      v-if="view == 'login'"
      :class="{
        'LoginDialog-form': true,
        '--default': true,
        '--validate': validate,
      }"
      method="post"
      @submit.prevent="submitLogin"
    >
      <div>
        <h1><strong>Anmelden</strong></h1>
        <p>
          Sie haben noch keinen Benutzer-Account?
          <a :href="registrationUrl">Jetzt registrieren</a><br />
          Sie möchten nur unseren Newsletter abonnieren?
          <a :href="newsletterUrl">Abonnieren</a>
        </p>
      </div>

      <input type="text" name="username" placeholder="Username" />
      <div class="LoginDialog-field field field_email">
        <label for="loginDialogEmail">E-Mail-Adresse</label>
        <input
          id="loginDialogEmail"
          v-model="email"
          type="email"
          autocomplete="email"
          required
          autofocus
          name="email"
          placeholder="ihre@email.de"
        />
      </div>

      <input-field
        class="LoginDialog-field field field_password"
        name="password"
        label="Passwort"
        field-type="password"
        :required="true"
        autocomplete="current-password"
        placeholder="••••••••"
        :model-value="password"
        form-name="loginDialog"
        @update:model-value="password = $event"
      />

      <div
        v-if="email == 'referent'"
        class="Message Message--warn"
        style="margin-bottom: 1em"
      >
        Bitte beachten Sie, dass der 'referent'-Zugang durch individuelle
        Zugänge ersetzt wurde. Ihren individuellen Zugang sollten Sie per E-Mail
        erhalten haben. Falls nicht, wenden Sie sich gerne an
        info@mund-pflege.net.
      </div>

      <div>
        <button
          type="submit"
          :disabled="disableSubmit"
          class="blue-button"
          @click="validate = true"
        >
          Login
        </button>
        <button @click.prevent="view = 'passwordRequest'">
          Passwort vergessen?
        </button>
      </div>
    </form>

    <form
      v-if="view == 'passwordRequest'"
      class="LoginDialog-form --default"
      method="post"
      @submit.prevent="submitPasswordRequest"
    >
      <div>
        <h1><strong>Passwort zurücksetzen</strong></h1>
        <p>Bitte geben Sie hier Ihre E-Mail-Adresse an.</p>
      </div>
      <input
        v-model="username"
        type="text"
        name="username"
        placeholder="Username"
      />
      <div class="LoginDialog-field field field_email">
        <label for="loginDialogEmail">E-Mail-Adresse</label>
        <input
          id="loginDialogEmail"
          v-model="email"
          type="email"
          required
          name="email"
          placeholder="ihre@email.de"
        />
      </div>
      <div class="DefaultDialog-buttons">
        <button
          type="submit"
          :disabled="disableSubmit"
          class="blue-button"
          @click="validate = true"
        >
          Passwort anfordern
        </button>
        <button class="button" @click.prevent="view = 'login'">
          Abbrechen
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import InputField from '../InputField/InputField.vue'

export default {
  components: {
    InputField,
  },
  data() {
    return {
      error: false,
      success: false,
      view: 'login',
      username: '',
      email: '',
      password: '',
      image: '',
      disableSubmit: false,
      validate: false,
      registrationUrl: '',
      newsletterUrl: '',
    }
  },
  mounted() {
    if (document.loginImage !== undefined) {
      this.image = document.loginImage
    }

    this.registrationUrl = document.registrationUrl
    this.newsletterUrl = document.newsletterUrl

    const loginDialogElement = document.querySelector('.LoginDialog')

    loginDialogElement.addEventListener('close', () => {
      this.view = 'login'
    })
  },
  methods: {
    close() {
      this.error = false
      this.success = false
      const loginDialogElement = document.querySelector('.LoginDialog')
      loginDialogElement.close()
    },
    submitPasswordRequest() {
      this.error = false
      this.success = false
      this.disableSubmit = true

      if (this.username != '') return false

      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue

      const defaultResetRequestMessage =
        'Sollten Sie mit der angegebenen E-Mail bei uns registriert sein, erhalten Sie in Kürze eine E-Mail mit einem neuen Passwort.'

      axios
        .post(
          document.jsVariables.apiBaseUrl + 'passwordrequest',
          {
            email: this.email,
          },
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              [tokenName]: tokenValue,
            },
          },
        )
        .then(() => {
          this.disableSubmit = false
          this.email = ''
          this.success = defaultResetRequestMessage
        })
        .catch((error) => {
          this.disableSubmit = false
          if (
            error.response.data.message == null ||
            error.response.data.message == ''
          ) {
            this.success = defaultResetRequestMessage
          } else {
            this.error = error.response.data.message
          }

          this.email = ''
        })
    },
    submitLogin() {
      this.error = false
      this.success = false
      this.disableSubmit = true

      if (this.username != '') return false

      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue

      axios
        .post(
          document.jsVariables.apiBaseUrl + 'login',
          {
            email: this.email,
            password: this.password,
          },
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              [tokenName]: tokenValue,
            },
          },
        )
        .then(() => {
          this.disableSubmit = false
          location.reload()
        })
        .catch((error) => {
          this.disableSubmit = false
          this.error = error.response.data.message
        })
    },
  },
}
</script>
