import Chart from 'chart.js/auto'

const StatisticGoogleSearchConsoleElement = document.querySelector(
  '.StatisticGoogleSearchConsole-canvas',
)

if (StatisticGoogleSearchConsoleElement) {
  ;(async function () {
    Chart.defaults.color = 'rgba(157, 167, 175, 1)'
    Chart.defaults.font = {
      size: 12,
      family: 'Red Hat Text',
      weight: 500,
    }

    new Chart(StatisticGoogleSearchConsoleElement, {
      type: 'line',
      options: {
        maintainAspectRatio: true,
        elements: {
          point: {
            radius: 1,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              boxWidth: 6,
              boxHeight: 6,
              useBorderRadius: true,
              usePointStyle: true,
              pointStyle: 'line',
              borderRadius: 3,
              font: {
                size: 17,
                weight: 600,
              },
              color: 'hsl(205, 76%, 10%)',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              autoSkip: true,
              maxRotation: 0,
              autoSkipPadding: 20,
            },
            grid: {
              display: false,
            },
            title: {
              color: 'rgba(157, 167, 175, 1)',
            },
          },
          // y: {
          //   grid: {
          //     beginAtZero: true,
          //   },
          //   ticks: {
          //     autoSkip: true,
          //     maxTicksLimit: 6,
          //   },
          // },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            // beginAtZero: true,
            grid: {
              // beginAtZero: true,
            },
            ticks: {
              autoSkip: true,
              // beginAtZero: true,
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',

            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
        },
      },
      data: {
        labels: document.jsVariables.googleStatistics.dates,
        datasets: [
          {
            label: 'Klicks',
            data: document.jsVariables.googleStatistics.clicks,
            tension: 0.1,
            borderColor: 'rgb(6, 29, 45)',
            borderWidth: 2,
            yAxisID: 'y',
          },
          {
            label: 'Impressionen',
            data: document.jsVariables.googleStatistics.impressions,
            tension: 0.1,
            borderColor: 'rgb(61, 197, 255)',
            borderWidth: 2,
            yAxisID: 'y1',
          },
        ],
      },
    })
  })()
}
