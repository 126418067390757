import { createApp } from 'vue'
import QuizModule from './QuizModule.vue'

const QuizElements = document.querySelectorAll('.Quiz')

Array.prototype.forEach.call(QuizElements, function (quizElement) {
  createApp(QuizModule, {
    quizId: parseInt(quizElement.getAttribute('data-quiz-id')),
  }).mount(quizElement)
})
