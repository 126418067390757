const directoryLinks = document.querySelectorAll('.Directory-link')

if (directoryLinks) {
  directoryLinks.forEach((link) => {
    link.addEventListener('click', () => {
      const targetElement = document.querySelector(
        link.getAttribute('href') + ' .MatrixChapter-details',
      )
      if (targetElement && !targetElement.open) {
        targetElement.open = true
      }
    })
  })
}
