<template>
  <form
    ref="form"
    method="post"
    class=""
    action="/api/requestAccountDeletion"
    @submit.prevent="submitDeletion"
  >
    <div class="info-text">
      <h1><strong>Profil löschen</strong></h1>
      <p>
        <b
          >Sind Sie sicher, dass Sie Ihren Zugang bei mund-pflege.net engültig
          löschen möchten?
        </b>
      </p>

      <p>
        Nach Klick auf "Löschen beantragen" erhalten Sie einen Bestätigungslink
        an Ihre hinterlegte E-Mail. Dieser ist 24-Stunden lang gültig. Mit Klick
        auf den Link in der E-Mail löschen Sie Ihren Account unwiederruflich.
      </p>

      <div class="DefaultDialog-buttons">
        <button type="submit" class="button button--red">
          Löschen beantragen
        </button>
        <button class="button" @click.prevent="close()">Abbrechen</button>
      </div>
    </div>
  </form>
  <button class="button--close" title="Dialog schließen" @click="close()">
    <i class="las la-times"></i>
  </button>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      image: '',
      disableSubmit: false,
    }
  },
  mounted() {
    if (document.loginImage !== undefined) {
      this.image = document.loginImage
    }
  },
  methods: {
    close() {
      this.$refs.form.parentNode.parentNode.close()
    },
    submitDeletion() {
      this.disableSubmit = true

      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue

      axios
        .post(document.jsVariables.apiBaseUrl + 'requestAccountDeletion', {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            [tokenName]: tokenValue,
          },
        })
        .then(() => {
          this.disableSubmit = false
          window.location = '/?confirmAccountDeletionRequest=true'
        })
        .catch(() => {
          this.disableSubmit = false
        })
    },
  },
}
</script>
