import { createApp } from 'vue'
import DeleteAccount from './DeleteAccount.vue'

const showDeleteAccountButton = document.getElementById('deleteAccountButton')
const deleteAccountDialogElement = document.getElementById(
  'deleteAccountDialog',
)

if (deleteAccountDialogElement) {
  showDeleteAccountButton.addEventListener('click', () => {
    deleteAccountDialogElement.showModal()
  })

  deleteAccountDialogElement.addEventListener('click', (event) => {
    if (event.target.nodeName === 'DIALOG') {
      deleteAccountDialogElement.close()
    }
  })
}

const DeleteAccountForm = document.querySelector('.DeleteAccount-form')

if (DeleteAccountForm) {
  createApp(DeleteAccount).mount(DeleteAccountForm)
}
