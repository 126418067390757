function toggleAllRepeaterMatrixClick(event) {
  const isExpanded = event.target.getAttribute('aria-expanded')
  const labelElement = event.target.querySelector('.RepeaterMatrix-toggleLabel')

  if (isExpanded === 'true') {
    toggleAllRepeaterMatrixChapters(false)
    event.target.setAttribute('aria-expanded', 'false')
    labelElement.innerHTML = 'anzeigen <i class="las la-plus"></i>'
  } else {
    toggleAllRepeaterMatrixChapters(true)
    event.target.setAttribute('aria-expanded', 'true')
    labelElement.innerHTML = 'ausblenden <i class="las la-minus"></i>'
  }
}

function toggleAllRepeaterMatrixChapters(expand) {
  const matrixChapters = document.querySelectorAll('.MatrixChapter-details')

  matrixChapters.forEach((chapter) => {
    if (expand) {
      chapter.open = true
    } else {
      chapter.open = false
    }
  })
}

const repeaterMatrixToggleButton = document.querySelector(
  '.RepeaterMatrix-toggleButton',
)

if (repeaterMatrixToggleButton) {
  repeaterMatrixToggleButton.addEventListener(
    'click',
    toggleAllRepeaterMatrixClick,
  )

  window.addEventListener('load', function () {
    if (document.location.hash != '') {
      const targetElement = document.querySelector(
        document.location.hash + ' .MatrixChapter-details',
      )

      if (!targetElement.open) {
        targetElement.open = true
      }

      if (
        !targetElement.parentElement.classList.contains(
          'Matrix-Chapter--depth0',
        )
      ) {
        const closedParent = document.querySelector(
          '.MatrixChapter-details:not(open):has(.MatrixChapter-details[open]',
        )

        if (closedParent) {
          closedParent.open = true
        }
      }
    }
  })
}
